import React from "react";
import GoogleMapReact from "google-map-react";
import MarkerImage from "../assets/images/BallMarker.png";

const defaultProps = {
  center: {
    lat: 59.125209,
    lng: 9.70497,
  },
  zoom: 15,
};

const Marker = () => {
  return (
    <>
      <img
        className="customer-pin"
        src={MarkerImage}
        alt="A marker pin with a padel ball inside."
      />
    </>
  );
};

const GoogleMap = () => (
  <div style={{ height: "250px", width: "auto" }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyDNx_9edocrKH50qRN-0hB3n7wRg8UG4X0" }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      <Marker lat={defaultProps.center.lat} lng={defaultProps.center.lng} />
    </GoogleMapReact>
  </div>
);

export default GoogleMap;

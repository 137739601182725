import React from "react";
import { FaInstagramSquare, FaPhone, FaEnvelope } from "react-icons/fa";
import stian from "../assets/images/stian.jpeg";
import GoogleMapComponent from "../components/GoogleMap";

export default function Footer() {
  return (
    <div className="mt-5 pt-4 pb-4 footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-xs-12 pt-4 about-company">
            <h4>Padel Universe</h4>
            <a
              href="https://www.instagram.com/padeluniverse.no"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div className="text-white-50">
                <FaInstagramSquare
                  style={{ fontSize: `40px`, marginRight: "10px" }}
                />
                <p
                  style={{
                    display: "inline",
                    fontSize: "18px",
                  }}
                >
                  padeluniverse.no
                </p>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-xs-12 pt-4 location">
            <h4 className="mt-lg-0 mt-sm-4">Kontakt</h4>
            <div style={{ display: "flex" }}>
              <div style={{ display: "block", marginRight: `10px` }}>
                <img
                  src={stian}
                  style={{ opacity: 0.8 }}
                  height="95"
                  alt="Stian T. Grimsgaard"
                />
              </div>
              <div style={{ margin: 0 }}>
                <p className="mb-0 text-white-50">
                  Daglig leder, <br />
                  Stian T. Grimsgaard
                </p>
                <a
                  href="tel:+(47) 91 69 58 07"
                  className="mb-0 text-white-50 text-decoration-none"
                  style={{ display: "block" }}
                >
                  <FaPhone className="me-3" />
                  +(47) 91 69 58 07
                </a>
                <a
                  href="mailto:contact@padeluniverse.no"
                  className="text-white-50"
                >
                  <FaEnvelope className="me-3" />
                  contact@padeluniverse.no
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-xs-12 pt-4 links">
            <h4 className="mt-lg-0 mt-sm-4">Her finner du oss</h4>
            <p className="text-white-50">Moen 13, 3946 Porsgrunn</p>
            <GoogleMapComponent />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col copyright">
            <p className="">
              <small className="text-white-50">
                © 2021. All Rights Reserved.
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
